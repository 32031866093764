<template>
  <div class="flex flex-col gap-y-10 p-5">
    <div class="grid gap-x-4 grid-cols-2 gap-y-8">
      <dok-input
        size="lg"
        d-placeholder="Taille"
        d-type="number"
        label="Taille"
        :d-model.sync="data.taille"
      >
        <template v-slot:rightIcon>
          <span class="font-EffraR" style="color: #aaaaaa">CM</span>
        </template>
      </dok-input>

      <dok-input
        size="lg"
        d-placeholder="Poid"
        d-type="number"
        label="Poids"
        :d-model.sync="data.poid"
      >
        <template v-slot:rightIcon>
          <span class="font-EffraR" style="color: #aaaaaa">KG</span>
        </template>
      </dok-input>
    </div>

    <dok-button
      size="lg"
      custom-class="flex items-center gap-x-2 justify-center"
      @click.native="onSave"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20.5 6l-11 11-5-5"
        />
      </svg>
      Sauvegarder
    </dok-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    bodyInfo: {
      type: Object,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.data = this.bodyInfo;
  },
  methods: {
    ...mapActions({
      updatePatientHealthProfile: "healthbook/UPDATE_PATIENT_HEALTH_PROFILE",
    }),
    onSave() {
      this.$vs.loading();
      this.updatePatientHealthProfile({
        patientId: this.patient._id,
        params: {
          height: this.data.taille,
          weight: this.data.poid,
        },
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Caractéristiques physiques modifiées avec succès",
            });
            return EventBus.$emit("RELOAD_RESUME_HELTHBOOK");
          }
          this.$vs.loading.close();
        },
      });
    },
  },
};
</script>

<style></style>
