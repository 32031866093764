<template>
  <div>
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
      style="border: solid 2px #ededed"
    >
      <div class="flex flex-row justify-between gap-x-1">
        <div class="flex flex-row gap-x-1 items-center">
          <img src="/svg/healthbook/syringe-line.svg" />
          <h1 class="text-xl font-EffraR text-black">Vaccins</h1>
        </div>

        <dok-button
          v-if="!updateMode"
          bg="white"
          custom-class="flex items-center gap-x-2 font-EffraM"
          @click.native="
            () => {
              updateMode = true;
            }
          "
        >
          <img src="/svg/pen.svg" /> Modifier
        </dok-button>
        <dok-button
          v-if="updateMode"
          custom-class="flex items-center gap-x-2 font-EffraM"
          size="md"
          @click.native="onSave"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            fill="none"
            viewBox="0 0 19 18"
          >
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.5 4.5l-8.25 8.25L3.5 9"
            />
          </svg>
          Sauvegarder
        </dok-button>
      </div>

      <hr class="border border-solid border-gray-200" />

      <div class="flex flex-col lg:flex-row gap-x-3 gap-y-4">
        <template v-if="vaccins.length">
          <span
            class="
              min-h-16
              flex
              items-start
              gap-x-4
              border border-solid border-otgray
              py-5
              px-2
              rounded-cu
            "
            v-for="(va, il) in vaccins"
            :key="il"
          >
            <div
              class="
                h-8
                w-8
                rounded-full
                cursor-pointer
                bg-dokBlue-lighter
                relative
                flex
                items-center
                justify-center
              "
              style="border: solid 1px #eaeaea"
            >
              <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
            </div>
            <div class="flex flex-col gap-y-2">
              <span class="font-EffraR text-base text-black">{{
                va.name
              }}</span>
              <div class="flex flex-col gap-y-1">
                <span class="font-EffraM text-labelGray">Date</span>
                <span class="text-sm font-EffraM text-black">{{
                  moment()(va.vaccinDate).format("DD/MM/YYYY")
                }}</span>
              </div>
            </div>
            <!-----------------------DELETE FROM LIST------------------------>
            <div
              class="
                h-6
                w-6
                rounded-full
                flex
                items-center
                justify-center
                cursor-pointer
              "
              style="background: #bc3939"
              v-if="updateMode"
              @click="onDeleteVaccin(va)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                />
              </svg>
            </div>
          </span>
        </template>
        <span
          v-if="updateMode"
          @click="callModal"
          class="
            flex
            items-center
            gap-x-2
            cursor-pointer
            font-EffraM
            text-lg text-dokBlue-ultra
            leading-loose
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#297AFB"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 3.75v10.5M3.75 9h10.5"
            />
          </svg>
          Ajouter
        </span>
      </div>

      <!-- If Array is empty -->
      <div
        class="flex flex-row gap-x-1 text-base font-EffraM"
        style="color: #8a8a8a"
        v-if="!vaccins.length"
      >
        <img src="/svg/consultation/info/x.svg" /> No available data
      </div>
    </div>

    <!------------------Modal--------------------->
    <modal
      :isActive="modal.is"
      :exitModal="onCloseModal"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="flex flex-col gap-y-8 p-5">
        <div class="flex justify-between items-center">
          <h1 class="text-xl font-EffraR text-black flex-1">Vaccination</h1>
          <dok-button bg="none" @click.native="onCloseModal">
            <img src="/svg/x.svg" />
          </dok-button>
        </div>

        <div class="flex flex-col gap-y-4">
          <dok-select
            label="Vaccins"
            size="lg"
            :d-model.sync="modal.data.vaccin"
          >
            <template v-slot:leftIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 19c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM21 21l-4.35-4.35"
                />
              </svg>
            </template>
            <template v-slot:options>
              <option :value="null" disabled>
                Sélectionnez les vaccins...
              </option>
              <option
                v-for="(item, index) in allVaccins"
                :key="index"
                :value="item._id"
              >
                {{ item.name }}
              </option>
            </template>
          </dok-select>

          <dok-input-date
            :d-model.sync="modal.data.date"
            size="lg"
          ></dok-input-date>
        </div>

        <dok-button
          :bg="modal.data.date && modal.data.vaccin ? 'blue' : 'gray'"
          size="lg"
          custom-class="flex items-center justify-center gap-x-2 text-white"
          @click.native="onAddNewVaccin"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            fill="none"
            viewBox="0 0 25 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12.5 5v14M5.5 12h14"
            />
          </svg>
          Ajouter
        </dok-button>
      </div>
    </modal>
  </div>
</template>

<script>
  import modal from "@/views/global-components/modal";
  import moment from "moment";
  import { EventBus } from "@/event-bus";

  export default {
    props: {
      vaccins: {
        type: Array,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
    },
    components: { modal },
    data() {
      return {
        allVaccins: [],
        updateMode: false,
        modal: {
          is: false,
          data: {
            vaccin: null,
            date: moment().format("YYYY-MM-DD"),
          },
        },
      };
    },
    created() {
      this.listVaccins();
    },
    methods: {
      moment() {
        moment.locale("fr");
        return moment;
      },
      async listVaccins() {
        const { data, ok } = await this.$store.dispatch(
          "healthbook/GET_VACCIN_LIST",
          {
            patientId: this.patient._id,
          }
        );

        if (ok) {
          this.allVaccins = data;
        }
      },
      onCloseModal() {
        this.modal.is = false;
        this.modal.data = {
          vaccin: null,
          date: moment().format("YYYY-MM-DD"),
        };
      },
      callModal() {
        this.modal.is = true;
      },
      onSave() {
        this.updateMode = false;
      },
      async onAddNewVaccin() {
        if (!this.modal.data.date || !this.modal.data.vaccin) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Remplissez les champs manquants",
          });
        }

        let findCurrentVaccin = this.allVaccins.find(
          (item) => item._id === this.modal.data.vaccin
        );

        if (!findCurrentVaccin) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Vaccin non valide",
          });
        }

        if (!moment(this.modal.data.date, "YYYY-MM-DD").isValid()) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Date not valid please try again",
          });
        }

        this.$vs.loading();
        await this.$store.dispatch("healthbook/SAVE_PATIENT_VACCIN", {
          patientId: this.patient._id,
          data: {
            vaccin: findCurrentVaccin._id,
            name: findCurrentVaccin.name,
            vaccinDate: this.modal.data.date,
          },
          onData: ({ ok, message }) => {
            if (!ok) {
              EventBus.$emit("flashDok", {
                type: "errorr",
                message,
              });

              return this.$vs.loading.close();
            }

            if (ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: "Vaccin ajouté avec succès",
              });

              EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

              this.$nextTick(() => {
                this.onCloseModal();
              });

              return;
            }
          },
        });
      },
      async onDeleteVaccin(item) {
        if (!item._id) {
          return EventBus.$emit("flashDok", {
            type: "success",
            message: "Erreur",
          });
        }

        this.$vs.loading();

        const { data } = await this.$store.dispatch(
          "healthbook/REMOVE_PATIENT_VACCIN",
          {
            patientId: this.patient._id,
            vaccinId: item._id,
          }
        );

        if (data.ok) {
          EventBus.$emit("flashDok", {
            type: "success",
            message: "Vaccin supprimé avec succès",
          });

          return EventBus.$emit("RELOAD_RESUME_HELTHBOOK");
        }

        this.$vs.loading.close();
      },
    },
  };
</script>
