<template>
  <div class="flex flex-col gap-y-6 p-5">
    <div class="grid grid-cols-2 gap-x-3 gap-y-3">
      <div
        v-for="(typ, index) in choices"
        :key="index"
        class="flex items-center gap-x-3 p-3 rounded-cu font-EffraR text-black"
        style="border: solid 1px #eaeaea;"
      >
        <div
          class="h-8 w-8 rounded-full cursor-pointer hover:bg-dokBlue-lighter relative flex items-center justify-center"
          style="border: solid 1px #eaeaea;"
          @click="dataEnceinte.healthbookProfilePregnancy = typ.value"
        >
          <i
            class="fas fa-check text-dokBlue-ultra text-xl"
            v-if="typ.value === dataEnceinte.healthbookProfilePregnancy"
          ></i>
        </div>

        {{ typ.name }}
      </div>
    </div>
    <template v-if="dataEnceinte.healthbookProfilePregnancy">
      <dok-input-date
        label="Depuis quand?"
        :d-model.sync="dataEnceinte.probableStartDate"
        size="lg"
      ></dok-input-date>

      <dok-input-date
        label="Date prévue d'accouchement?"
        :d-model.sync="dataEnceinte.expectedDeliveryDate"
        size="lg"
      ></dok-input-date>
    </template>

    <dok-button
      size="lg"
      custom-class="font-EffraM rounded-cu"
      @click.native="onSave"
    >
      Sauvegarder
    </dok-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    enceinte: {
      type: Object,
      required: false
    },
    patient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      choices: [
        { name: "Oui", value: true },
        { name: "Non", value: false }
      ],
      dataEnceinte: {
        healthbookProfilePregnancy: false,
        probableStartDate: "",
        expectedDeliveryDate: ""
      }
    };
  },
  created() {
    this.dataEnceinte.healthbookProfilePregnancy = this.enceinte.isPregnant;
    this.dataEnceinte.probableStartDate = this.enceinte.probableStartDate;
    this.dataEnceinte.expectedDeliveryDate = this.enceinte.expectedDeliveryDate;
  },
  methods: {
    ...mapActions({
      updatePatientHealthProfile: "healthbook/UPDATE_PATIENT_HEALTH_PROFILE"
    }),
    onSave() {
      this.$vs.loading();
      this.updatePatientHealthProfile({
        patientId: this.patient._id,
        params: {
          pregnancy: {
            isPregnant: this.dataEnceinte.healthbookProfilePregnancy,
            probableStartDate: this.dataEnceinte.probableStartDate,
            expectedDeliveryDate: this.dataEnceinte.expectedDeliveryDate
          }
        },
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Enceinte modifié avec succès"
            });
            return EventBus.$emit("RELOAD_RESUME_HELTHBOOK");
          }
          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>

<style>
</style>