<template>
  <div>
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
      style="border: solid 2px #ededed"
    >
      <div class="flex flex-row justify-between gap-x-1">
        <div class="flex flex-row gap-x-1 items-center">
          <img src="/svg/healthbook/lungs-line.svg" />
          <h1 class="text-xl font-EffraR text-black">
            Allergies et intolérences
          </h1>
        </div>

        <dok-button
          bg="white"
          custom-class="flex items-center gap-x-2 font-EffraM"
          v-if="!modeUpdate"
          @click.native="modeUpdate = true"
        >
          <img src="/svg/pen.svg" /> Modifier
        </dok-button>

        <dok-button
          v-if="modeUpdate"
          custom-class="flex items-center gap-x-2 font-EffraM"
          size="md"
          @click.native="modeUpdate = false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            fill="none"
            viewBox="0 0 19 18"
          >
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.5 4.5l-8.25 8.25L3.5 9"
            />
          </svg>
          Sauvegarder
        </dok-button>
      </div>

      <hr class="border border-solid border-gray-200" />

      <div
        class="grid gap-x-3 gap-y-4 grid-cols-1 lg:grid-cols-4 items-baseline"
      >
        <!------------------------------------------Alimentaires--------------------------------------------------------------------->
        <div
          class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
          style="border: solid 1px #ededed"
        >
          <!----TITLE---->
          <h3 class="font-EffraR text-base" style="color: #5b5b5b">
            Alimentaires
          </h3>
          <!----NO DATA---->
          <div
            v-if="allergies && !allergies.alimentaires"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <!----LIST---->
          <ul
            class="flex flex-col gap-y-2"
            v-if="allergies && allergies.alimentaires"
          >
            <li
              style="border: solid 1px #eaeaea"
              class="
                font-EffraM
                text-base
                p-3
                rounded-cu
                flex
                items-center
                justify-between
              "
              v-for="(alimen, index) in allergies.alimentaires.allergies"
              :key="index"
            >
              <div class="flex flex-row items-center gap-x-3 w-11/12">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    bg-dokBlue-lighter
                    flex
                    justify-center
                    items-center
                  "
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                {{ alimen.allergyName }}
              </div>

              <div
                class="
                  h-6
                  w-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                style="background: #bc3939"
                @click.prevent="onDeleteAlimentaire(alimen)"
                v-if="modeUpdate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                  />
                </svg>
              </div>
            </li>
          </ul>
          <!----ACTIONS---->
          <dok-button
            v-if="modeUpdate"
            size="md"
            bg="white"
            custom-class="flex item-center justify-start gap-x-2 font-EffraM"
            @click.native="onOpenModal('alimentaires')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#297AFB"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 3.75v10.5M3.75 9h10.5"
              />
            </svg>
            Ajouter
          </dok-button>
        </div>
        <!------------------------------------------Medicaments--------------------------------------------------------------------->
        <div
          class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
          style="border: solid 1px #ededed"
        >
          <!----TITLE---->
          <h3 class="font-EffraR text-base" style="color: #5b5b5b">
            Médicamenteuses
          </h3>
          <!----NO DATA---->
          <div
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
            v-if="allergies && !allergies.medicamenteuses"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <!----LIST---->
          <ul
            class="flex flex-col gap-y-2"
            v-if="allergies && allergies.medicamenteuses"
          >
            <li
              style="border: solid 1px #eaeaea"
              class="
                font-EffraM
                text-base
                p-3
                rounded-cu
                flex
                items-center
                justify-between
              "
              v-for="(alimen, index) in allergies.medicamenteuses.allergies"
              :key="index"
            >
              <div class="flex flex-row items-center gap-x-3 w-11/12">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    bg-dokBlue-lighter
                    flex
                    justify-center
                    items-center
                  "
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                {{ alimen.allergyName }}
              </div>

              <div
                class="
                  h-6
                  w-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                style="background: #bc3939"
                @click.prevent="onDeleteAlimentaire(alimen)"
                v-if="modeUpdate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                  />
                </svg>
              </div>
            </li>
          </ul>
          <!----ACTIONS---->
          <dok-button
            v-if="modeUpdate"
            size="md"
            bg="white"
            custom-class="flex items-center justify-start gap-x-2 font-EffraM"
            @click.native="onOpenModal('medicaments')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#297AFB"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 3.75v10.5M3.75 9h10.5"
              />
            </svg>
            Ajouter
          </dok-button>
        </div>
        <!------------------------------------------Respiratoires--------------------------------------------------------------------->
        <div
          class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
          style="border: solid 1px #ededed"
        >
          <!----TITLE---->
          <h3 class="font-EffraR text-base" style="color: #5b5b5b">
            Respiratoires
          </h3>
          <!----NO DATA---->
          <div
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
            v-if="allergies && !allergies.respiratoires"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <!----LIST---->
          <ul
            class="flex flex-col gap-y-2"
            v-if="allergies && allergies.respiratoires"
          >
            <li
              style="border: solid 1px #eaeaea"
              class="
                font-EffraM
                text-base
                p-3
                rounded-cu
                flex
                items-center
                justify-between
              "
              v-for="(alimen, index) in allergies.respiratoires.allergies"
              :key="index"
            >
              <div class="flex flex-row items-center gap-x-3 w-11/12">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    bg-dokBlue-lighter
                    flex
                    justify-center
                    items-center
                  "
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                {{ alimen.allergyName }}
              </div>

              <div
                class="
                  h-6
                  w-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                style="background: #bc3939"
                @click.prevent="onDeleteAlimentaire(alimen)"
                v-if="modeUpdate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                  />
                </svg>
              </div>
            </li>
          </ul>
          <!----ACTIONS---->
          <dok-button
            size="md"
            bg="white"
            custom-class="flex item-center justify-start gap-x-2 font-EffraM"
            @click.native="onOpenModal('respiratoires')"
            v-if="modeUpdate"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#297AFB"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 3.75v10.5M3.75 9h10.5"
              />
            </svg>
            Ajouter
          </dok-button>
        </div>
        <!------------------------------------------DeContact--------------------------------------------------------------------->
        <div
          class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
          style="border: solid 1px #ededed"
        >
          <!----TITLE---->
          <h3 class="font-EffraR text-base" style="color: #5b5b5b">
            De contact
          </h3>
          <!----NO DATA---->
          <div
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
            v-if="allergies && !allergies['de-contact']"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <!----LIST---->
          <ul
            class="flex flex-col gap-y-2"
            v-if="allergies && allergies['de-contact']"
          >
            <li
              style="border: solid 1px #eaeaea"
              class="
                font-EffraM
                text-base
                p-3
                rounded-cu
                flex
                items-center
                justify-between
              "
              v-for="(alimen, index) in allergies['de-contact'].allergies"
              :key="index"
            >
              <div class="flex flex-row items-center gap-x-3 w-11/12">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    bg-dokBlue-lighter
                    flex
                    justify-center
                    items-center
                  "
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                {{ alimen.allergyName }}
              </div>

              <div
                class="
                  h-6
                  w-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                style="background: #bc3939"
                @click.prevent="onDeleteAlimentaire(alimen)"
                v-if="modeUpdate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                  />
                </svg>
              </div>
            </li>
          </ul>
          <!----ACTIONS---->
          <dok-button
            v-if="modeUpdate"
            size="md"
            bg="white"
            custom-class="flex item-center justify-start gap-x-2 font-EffraM"
            @click.native="onOpenModal('de-contact')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18"
            >
              <path
                stroke="#297AFB"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 3.75v10.5M3.75 9h10.5"
              />
            </svg>
            Ajouter
          </dok-button>
        </div>
      </div>
    </div>

    <!------------------------------------------MODAL----------------------------------------------->
    <modal
      :isActive="modal.is"
      :exitModal="onCloseModal"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="p-5 flex flex-col gap-y-2 h-full relative">
        <div class="flex flex-col gap-y-6">
          <div class="flex justify-between">
            <h1 class="flex-1 font-EffraR text-2xl">Allergies alimentaires</h1>
            <button
              @click="onCloseModal"
              class="bg-transparent border-none focus:outline-none"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#111111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#111111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <div class="relative">
            <div class="flex flex-row gap-x-4">
              <div class="relative w-full">
                <dok-input
                  size="lg"
                  d-placeholder="Recherchez ..."
                  d-type="text"
                  :d-model.sync="searchFild"
                  :d-focus="
                    () => {
                      showList = true;
                    }
                  "
                >
                  <template v-slot:leftIcon>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="#AAAAAA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21 20.9999L16.65 16.6499"
                        stroke="#AAAAAA"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </template>
                </dok-input>
              </div>
              <dok-button
                bg="bluelighter"
                size="lg"
                custom-class="px-8 font-EffraM"
                @click.native="onCreate"
              >
                Ajouter
              </dok-button>
            </div>
            <!-- LIST -->
            <div
              v-if="showList"
              class="border w-full rounded text-sm bg-bgGrey mt-2"
            >
              <template v-if="modal.listAllergie.length">
                <div class="flex justify-end py-1 px-2">
                  <img
                    src="/svg/x.svg"
                    class="cursor-pointer"
                    @click="
                      () => {
                        showList = false;
                      }
                    "
                  />
                </div>
                <div class="max-h-300 overflow-y-scroll scroll">
                  <div
                    v-for="(item, index) in modal.listAllergie"
                    :key="index"
                    @click="setItem(item)"
                    class="
                      px-2
                      py-2
                      font-EffraR
                      text-base
                      cursor-pointer
                      hover:bg-grey-light
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
              </template>

              <template v-if="!modal.listAllergie.length">
                <div class="font-EffraR text-center py-2 w-full">No Data !</div>
              </template>
            </div>
            <!-- END LIST -->
          </div>
        </div>
        <div
          class="
            max-h-400
            overflow-y-scroll
            flex flex-col
            gap-y-2
            scroll-items
            pr-2
          "
        >
          <!-- Items -->
          <ul class="flex flex-col gap-y-2">
            <li
              style="border: solid 1px #eaeaea"
              class="
                font-EffraM
                text-base
                p-3
                rounded-cu
                flex
                items-center
                justify-between
              "
              v-for="(alimen, index) in typeList"
              :key="index"
            >
              <div class="flex flex-row items-center gap-x-3 w-11/12">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    bg-dokBlue-lighter
                    flex
                    justify-center
                    items-center
                  "
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                {{ alimen.allergyName }}
              </div>

              <div
                class="
                  h-6
                  w-6
                  rounded-full
                  flex
                  items-center
                  justify-center
                  cursor-pointer
                "
                style="background: #bc3939"
                @click.prevent="onDeleteAlimentaire(alimen)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                  />
                </svg>
              </div>
            </li>
          </ul>
          <!-- End Items -->
        </div>
        <div>
          <dok-button
            size="lg"
            custom-class="w-full"
            @click.native="
              () => {
                formCreate = {};
                modal = {
                  is: false,
                  type: null,
                  listAllergie: [],
                  tempListAllergie: [],
                };
              }
            "
          >
            Valider
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import { EventBus } from "@/event-bus";
  import modal from "@/views/global-components/modal";
  import _ from "lodash";

  export default {
    components: { modal },
    props: {
      allergies: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
    },
    watch: {
      searchFild(newVal, oldVal) {
        if (newVal.length < 1) {
          this.modal.listAllergie = this.modal.tempListAllergie;
        }

        if (newVal.length > 2) {
          this.showList = true;

          let filterList = this.modal.listAllergie.filter((item) => {
            return item.name.toLowerCase().indexOf(newVal.toLowerCase()) >= 0;
          });

          this.modal.listAllergie = filterList;
        }
      },
    },
    data() {
      return {
        modeUpdate: false,
        searchFild: null,
        showList: false,
        formCreate: {},
        modal: {
          is: false,
          type: null,
          listAllergie: [],
          tempListAllergie: [],
        },
      };
    },
    computed: {
      typeList() {
        if (this.modal.type === "alimentaires") {
          return this.allergies.alimentaires &&
            this.allergies.alimentaires.allergies
            ? this.allergies.alimentaires.allergies
            : [];
        }

        if (this.modal.type === "medicamenteuses") {
          return this.allergies.medicamenteuses &&
            this.allergies.medicamenteuses.allergies
            ? this.allergies.medicamenteuses.allergies
            : [];
        }

        if (this.modal.type === "respiratoires") {
          return this.allergies.respiratoires &&
            this.allergies.respiratoires.allergies
            ? this.allergies.respiratoires.allergies
            : [];
        }

        if (this.modal.type === "de-contact") {
          return this.allergies["de-contact"] &&
            this.allergies["de-contact"].allergies
            ? this.allergies["de-contact"].allergies
            : [];
        }

        return [];
      },
    },
    methods: {
      onOpenModal(type) {
        this.$vs.loading();

        if (type !== "medicaments") {
          this.modal.type = type;
        } else {
          this.modal.type = "medicamenteuses";
        }

        this.$nextTick(() => {
          this.getListAllergie(this.modal.type);
        });
      },
      onCloseModal() {
        this.modal = {
          is: false,
          type: null,
          listAllergie: [],
          tempListAllergie: [],
        };

        this.searchFild = null;
      },
      setItem(item) {
        if (!item.name) return;

        this.formCreate = {
          category: item.categorySlug,
          allergyName: item.name,
          allergy: item._id,
          moreInfo: "",
        };

        this.searchFild = item.name;

        this.$nextTick(() => {
          this.showList = false;
        });
      },
      async onCreate() {
        if (!this.formCreate.allergyName) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Remplissez les champs manquants",
            timerAlert: 8000,
          });
        }

        this.$vs.loading();
        await this.$store.dispatch("healthbook/SAVE_PATIENT_ALLERGIE", {
          patientId: this.patient._id,
          data: {
            ...this.formCreate,
          },
          onData: ({ ok, data }) => {
            if (ok) {
              this.formCreate = {};
              this.showList = false;
              this.searchFild = "";

              EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

              return;
            }

            this.$vs.loading.close();
          },
        });
      },
      async getListAllergie(slug) {
        const { ok, data } = await this.$store.dispatch(
          "healthbook/GET_ALLERGIE_LIST",
          {
            category: slug,
          }
        );

        if (ok) {
          this.modal.listAllergie = data;
          this.modal.tempListAllergie = _.cloneDeep(data);

          this.$nextTick(() => {
            this.modal.is = true;
            this.$vs.loading.close();
          });
        }
      },
      async onDeleteAlimentaire(item) {
        this.$vs.loading();

        await this.$store.dispatch("healthbook/REMOVE_PATIENT_ALLERGIE", {
          patientId: this.patient._id,
          allergy: item._id,
          onData: ({ ok }) => {
            if (ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: "Allergie supprimée avec succès",
                timerAlert: 8000,
              });

              EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

              return;
            }

            this.$vs.loading.close();
          },
        });
      },
    },
  };
</script>

<style lang="scss">
.scroll-items {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #767676;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}
</style>
