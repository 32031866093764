<!-- 
Helthbook Note :
      *Null =  Demande d'accès au carnet de santé
      *PENDING = Demande d'accès en attente
      *REJECTED = Renvoyer une demande d'accès au carnet de santé
      *AUTHORIZED = Has Access 
-->
<template>
  <section id="helthBook" class="flex flex-col gap-y-3">
    <template v-if="accessHelthbook === 'AUTHORIZED'">
      <template v-if="!loader">
        <dok-general-info
          :bloodType="generalInfo.bloodType"
          :bodyInfo="{
            taille: generalInfo.height,
            poid: generalInfo.weight,
            imc: generalInfo.imc
          }"
          :smoking="{
            ...generalInfo.smoking
          }"
          :enceinte="{ ...generalInfo.pregnancy }"
          :patient="patient"
        ></dok-general-info>
        <dok-vaccins
          :vaccins="generalInfo.vaccins"
          :patient="patient"
        ></dok-vaccins>
        <dok-allergies
          :allergies="generalInfo.allergies"
          :patient="patient"
        ></dok-allergies>
        <dok-antecedents
          :data="{
            familiaux: generalInfo.antecedentFamiliaux,
            chirurgicaux: generalInfo.antecedentChirurgicaux,
            medicaux: generalInfo.antecedentMedicaux
          }"
          :patient="patient"
        ></dok-antecedents>
        <dok-traitements-chroniques
          :data="generalInfo.medicaments"
          :patient="patient"
        >
        </dok-traitements-chroniques>
      </template>
      <template v-if="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </template>

    <template v-if="accessHelthbook !== 'AUTHORIZED'">
      <div
        class="bg-white px-8 rounded-lg py-5 flex items-center gap-x-2 justify-center w-full h-200"
        style="border: solid 2px #ededed;"
      >
        <template v-if="!accessHelthbook">
          <div class="flex flex-col gap-y-6 items-center">
            <div
              class="flex flex-row gap-x-3 items-center font-EffraM text-base text-black font-medium"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="none"
                  viewBox="0 0 26 26"
                >
                  <circle cx="13" cy="13" r="13" fill="#EEE" />
                  <g
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    clip-path="url(#clip0)"
                  >
                    <path
                      stroke-width="1.5"
                      d="M13 18c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5zM13 15v-2"
                    />
                    <path stroke-width="2" d="M13 11h.005" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <path
                        fill="#fff"
                        d="M0 0H12V12H0z"
                        transform="translate(7 7)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>

              Pour accéder aux informations du dossier médical, envoyer une
              demande au patient
            </div>
            <dok-button
              size="lg"
              custom-class="px-10 flex items-center gap-x-2"
              @click.native="onSentRequestHelthbook"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                fill="none"
                viewBox="0 0 25 24"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.5 3h6c1.06 0 2.078.421 2.828 1.172.75.75 1.172 1.767 1.172 2.828v14c0-.796-.316-1.559-.879-2.121C11.06 18.316 10.296 18 9.5 18h-7V3zM22.5 3h-6c-1.06 0-2.078.421-2.828 1.172-.75.75-1.172 1.767-1.172 2.828v14c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h7V3z"
                />
              </svg>

              Demande d'accès au carnet de santé
            </dok-button>
          </div>
        </template>
        <template v-if="accessHelthbook === 'PENDING'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            class="animate-spin"
          >
            <path
              stroke="#AE851A"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
              d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
            />
          </svg>
          <span class="font-EffraM text-lg" style="color: #AE851A;"
            >Demande d'accès en attente...</span
          >
        </template>
        <template v-if="accessHelthbook === 'REJECTED'">
          <dok-button
            size="lg"
            custom-class="px-10 flex items-center gap-x-2 hover:bg-opacity-80 group"
            @click.native="onSentRequestHelthbook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
              class="group-hover:animate-spin"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M23 4v6h-6M1 20v-6h6"
              />
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3.51 9c.507-1.433 1.37-2.715 2.505-3.725 1.137-1.01 2.51-1.715 3.993-2.05 1.483-.336 3.027-.29 4.487.132s2.79 1.208 3.865 2.283L23 10M1 14l4.64 4.36c1.075 1.075 2.404 1.86 3.865 2.283 1.46.423 3.004.468 4.487.133 1.482-.336 2.856-1.041 3.992-2.051 1.137-1.01 1.999-2.292 2.506-3.725"
              />
            </svg>
            Renvoyer une demande d'accès au carnet de santé...
          </dok-button>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import dokGeneralInfo from "./layouts/general_information.vue";
import dokVaccins from "./layouts/vaccins.vue";
import dokAllergies from "./layouts/allergies_intolerences.vue";
import dokAntecedents from "./layouts/antecedents.vue";
import dokTraitementsChroniques from "./layouts/traitements_chroniques.vue";

export default {
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      accessHelthbook: null,
      loader: true,
      generalInfo: {}
    };
  },
  components: {
    dokGeneralInfo,
    dokVaccins,
    dokAllergies,
    dokAntecedents,
    dokTraitementsChroniques
  },
  mounted() {
    EventBus.$on("RELOAD_RESUME_HELTHBOOK", this.getResume);
  },
  created() {
    this.onCheckAccessHelthbook();
  },
  methods: {
    ...mapActions({
      checkAuthorization: "healthbook/CHECK_AUTHORIZATION",
      requestAuthorization: "authorization/REQUEST_AUTHORIZATION"
    }),
    async onSentRequestHelthbook() {
      this.$vs.loading();
      await this.requestAuthorization({
        patientId: this.patient._id,
        authScope: "HEALTH-BOOK",
        onData: ({ message, ok, data }) => {
          console.log(ok, data);
          if (ok) {
            this.accessHelthbook = "PENDING";
          }

          EventBus.$emit("flashDok", {
            type: ok ? "success" : "error",
            message: message,
            timerAlert: 5000
          });

          this.$vs.loading.close();
        }
      });
    },
    async onCheckAccessHelthbook() {
      if (!this.patient._id) {
        return console.log("Canon't be load healthbook");
      }
      await this.checkAuthorization({
        patientId: this.patient._id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.accessHelthbook = data;
            if (data === "AUTHORIZED") {
              this.getResume();
            }
          }
        }
      });
    },
    async getResume() {
      const { data, ok } = await this.$store.dispatch(
        "healthbook/GET_PATIENT_HEALT_BOOK",
        {
          patientId: this.patient._id
        }
      );

      if (ok) {
        this.generalInfo = data;

        if (!data.smoking) {
          this.generalInfo.smoking = { isSmoker: false };
        }

        this.$nextTick(() => {
          this.$vs.loading.close();

          setTimeout(() => {
            this.loader = false;
          }, 1000);
        });
      }
    }
  }
};
</script>
