<template>
  <div class="flex flex-col gap-y-2 p-5">
    <p class="text-labelGray text-base font-EffraM">Fumeur</p>

    <div class="grid grid-cols-2 gap-x-3 gap-y-3">
      <div
        v-for="(typ, index) in choices"
        :key="index"
        class="flex items-center gap-x-3 p-3 rounded-cu font-EffraR text-black"
        style="border: solid 1px #eaeaea;"
      >
        <div
          class="h-8 w-8 rounded-full cursor-pointer hover:bg-dokBlue-lighter relative flex items-center justify-center"
          style="border: solid 1px #eaeaea;"
          @click="dataSmoke.isSmoker = typ.value"
        >
          <i
            class="fas fa-check text-dokBlue-ultra text-xl"
            v-if="typ.value === dataSmoke.isSmoker"
          ></i>
        </div>

        {{ typ.name }}
      </div>
    </div>

    <dok-button
      size="lg"
      custom-class="flex items-center gap-x-2 justify-center"
      @click.native="onSave"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20.5 6l-11 11-5-5"
        />
      </svg>
      Sauvegarder
    </dok-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    patient: {
      type: Object,
      required: true
    },
    smoking: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      choices: [
        { name: "Oui", value: true },
        { name: "Non", value: false }
      ],
      dataSmoke: {}
    };
  },
  created() {
    this.dataSmoke = this.smoking;
  },
  methods: {
    ...mapActions({
      updatePatientHealthProfile: "healthbook/UPDATE_PATIENT_HEALTH_PROFILE"
    }),
    onSave() {
      this.$vs.loading();
      this.updatePatientHealthProfile({
        patientId: this.patient._id,
        params: {
          smoking: {
            isSmoker: this.dataSmoke.isSmoker
          }
        },
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Mode de vie modifié avec succès"
            });
            return EventBus.$emit("RELOAD_RESUME_HELTHBOOK");
          }
          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>

<style></style>
