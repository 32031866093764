<template>
  <div class="flex items-center gap-x-2">
    <div
      class="font-EffraM text-base"
      :class="isActiveToggle ? 'text-dokBlue-ultra' : 'text-grayaaa'"
    >
      {{ isActiveToggle ? "Activé" : "Désactivé" }}
    </div>
    <div
      style="width: 56px;"
      class="relative h-8 rounded-full flex items-center"
      :class="
        isActiveToggle
          ? 'bg-dokBlue-ultra'
          : 'bg-transparent border-2 border-solid border-grayaaa'
      "
    >
      <span class="h-full flex items-center pl-2" v-if="isActiveToggle">
        <i class="fas fa-check text-white"></i>
      </span>
      <div
        class="h-3 w-3 rounded-full absolute cursor-pointer"
        :class="isActiveToggle ? 'is-right bg-white' : 'is-left bg-grayaaa'"
        @click="changeStatus"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isActiveToggle: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.$emit("update:isActive", value);
      }
    }
  },
  methods: {
    changeStatus() {
      event.preventDefault();

      if (this.isActiveToggle) {
        this.isActiveToggle = false;
        return;
      }

      this.isActiveToggle = true;
    }
  }
};
</script>

<style>
.is-left {
  left: 3px;
}

.is-right {
  right: 3px;
}
</style>
