<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed;"
  >
    <div class="flex flex-row justify-between gap-x-1">
      <div class="flex flex-row gap-x-1 items-center">
        <img src="/svg/healthbook/capsule-line.svg" />
        <h1 class="text-xl font-EffraR text-black">
          Traitements chroniques
        </h1>
      </div>

      <dok-button
        bg="white"
        custom-class="flex items-center gap-x-2 font-EffraM"
        @click.native="
          () => {
            modeUpdate = true;
          }
        "
        v-if="!modeUpdate"
      >
        <img src="/svg/pen.svg" /> Modifier
      </dok-button>
      <dok-button
        v-if="modeUpdate"
        custom-class="flex items-center gap-x-2 font-EffraM"
        size="md"
        @click.native="modeUpdate = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          fill="none"
          viewBox="0 0 19 18"
        >
          <path
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.5 4.5l-8.25 8.25L3.5 9"
          />
        </svg>
        Sauvegarder
      </dok-button>
    </div>

    <hr class="border border-solid border-gray-200" />

    <!-- List Medicaments -->
    <div class="flex flex-col gap-y-3" v-if="data.length">
      <div
        class="hidden lg:grid grid-cols-3 gap-x-3 p-5 rounded-lg"
        style="background: #f3f3f3;"
      >
        <div class="font-EffraR text-black text-base">
          Nom du Aller/complément
        </div>
        <div class="font-EffraR text-black text-base">
          Pour quelle pathologie?
        </div>
        <div class="font-EffraR text-black text-base">Le dosage quotidien</div>
      </div>

      <div
        class="grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-1 p-5 rounded-lg"
        style="border: solid 1px #ededed;"
        v-for="(med, index) in data"
        :key="index"
      >
        <div class="flex flex-col gap-y-1">
          <p class="block lg:hidden font-EffraM text-sm text-labelGray">
            Nom du Aller/complément :
          </p>
          <span class="font-EffraM text-black text-base">{{ med.name }}</span>
        </div>
        <div class="font-EffraM text-black text-base">
          <p class="block lg:hidden font-EffraM text-sm text-labelGray">
            Pour quelle pathologie?
          </p>
          <span class="font-EffraM text-black text-base">
            {{ med.doses }}
            {{
              $medicamentDosageUnits[med.doseUnit]
                ? $medicamentDosageUnits[med.doseUnit].unity
                : ""
            }}
            {{ med.prises ? " - " + med.prises + " x/ " + "jours" : "" }}
          </span>
        </div>
        <div class="flex justify-between">
          <div class="flex-1 flex flex-col">
            <p class="block lg:hidden font-EffraM text-sm text-labelGray">
              Le dosage quotidien
            </p>
            <div class="font-EffraM text-black text-base">
              {{ med.moreInfo }}
            </div>
          </div>

          <dok-button
            bg="no-class"
            size="delete"
            custom-class="w-6 h-6 flex justify-center items-center"
            @click.native="onDelete(med)"
            v-if="modeUpdate"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.5 3.5l-7 7M3.5 3.5l7 7"
              />
            </svg>
          </dok-button>
        </div>
      </div>
    </div>
    <!-- End Of List Medicaments -->

    <!-- If Array is empty -->
    <div
      class="flex flex-row gap-x-1 text-base font-EffraM"
      style="color: #8a8a8a;"
      v-if="!data.length"
    >
      <img src="/svg/consultation/info/x.svg" /> Pas de données
    </div>

    <!-- Button Create new medicament -->
    <div
      class="w-full flex items-center justify-center"
      style="border-radius: 8px; border: solid 1px #eee; height: 80px;"
      v-if="modeUpdate"
    >
      <dok-button
        bg="white"
        size="lg"
        custom-class="flex items-center justify-center gap-x-2 font-EffraM"
        @click.native="modal.is = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="#297AFB"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 3.75v10.5M3.75 9h10.5"
          />
        </svg>
        Ajouter
      </dok-button>
    </div>

    <!------------------------------------ Modal ------------------------------->
    <modal
      :isActive="modal.is"
      :exitModal="onCloseModal"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="p-5 flex flex-col gap-y-6">
        <div class="flex justify-between items-center gap-x-2">
          <h1 class="text-xl font-EffraR text-black flex-1">
            Traitements Chroniques
          </h1>
          <dok-button bg="none" @click.native="onCloseModal">
            <img src="/svg/x.svg" />
          </dok-button>
        </div>
        <div class="flex flex-col gap-y-4">
          <input-medicaments
            d-label="Nom du médicament"
            d-placeholder="Recherchez..."
            :set-item-parent="setMedicament"
            v-model="formCreate.medicament"
          >
          </input-medicaments>
          <dok-input
            size="lg"
            d-type="text"
            label="Pour quelle maladie?"
            :d-model.sync="formCreate.for"
            d-placeholder="Recherchez..."
          >
            <template v-slot:leftIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 2H6c-.53 0-1.04.21-1.414.586C4.21 2.96 4 3.47 4 4v16c0 .53.21 1.04.586 1.414C4.96 21.79 5.47 22 6 22h12c.53 0 1.04-.21 1.414-.586C19.79 21.04 20 20.53 20 20V8l-6-6z"
                />
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 2v6h6M16 13H8M16 17H8M10 9H8"
                />
              </svg>
            </template>
          </dok-input>
          <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-8 items-baseline"
          >
            <!-- Nombre de prise -->
            <div
              class="relative p-1 rounded-cu hover:bg-greyx cursor-pointer flex flex-col gap-y-1"
            >
              <label
                class="font-EffraR text-sm"
                style="color: #767676;"
                for="numbPrise"
              >
                Dosage
              </label>
              <input
                id="numbPrise"
                placeholder="Dosage/Prise"
                class="border-0 bg-transparent font-EffraR"
                type="number"
                v-model="formCreate.dailyDosage"
              />
            </div>
            <!-- Dosage/prise -->
            <div
              class="relative p-1 rounded-cu hover:bg-greyx cursor-pointer flex flex-col gap-y-1"
            >
              <label
                class="font-EffraR text-sm"
                style="color: #767676;"
                for="dosageSelect"
              >
                Unité (g, sachet, gelule...)
              </label>
              <select
                id="dosageSelect"
                class="border-0 bg-transparent font-EffraR text-base"
                v-model="formCreate.doseUnit"
              >
                <option :value="null" selected disabled
                  >Sélectionnez une unité ...</option
                >
                <option
                  v-for="(dure, index) in $medicamentDosageUnits"
                  :key="index"
                  :value="index"
                >
                  {{ `${dure.unity}` }}
                </option>
              </select>
            </div>
            <!-- Number De Prise/Jour -->
            <div
              class="relative p-1 rounded-cu hover:bg-greyx cursor-pointer flex flex-col gap-y-1"
            >
              <label
                class="font-EffraR text-sm"
                style="color: #767676;"
                for="dayNumber"
              >
                Nombre de prise/jour
              </label>
              <select
                id="dayNumber"
                class="border-0 bg-transparent font-EffraR text-base"
                v-model="formCreate.numberOfTake"
              >
                <option disabled selected :value="null">ex. 3x/Jour</option>
                <option
                  v-for="(dure, index) in numberPrise()"
                  :key="index"
                  :value="dure.number"
                >
                  {{ `${dure.label}` }}
                </option>
              </select>
            </div>
          </div>
          <dok-button
            size="lg"
            bg="blue"
            custom-class="flex items-center justify-center gap-x-2"
            @click.native="onSave"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 6L9 17l-5-5"
              />
            </svg>

            Ajouter
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "@/views/global-components/modal";
import _ from "lodash";
import { EventBus } from "@/event-bus";
import inputMedicaments from "@/views/dashboard/consultations/components/prescriptions/layouts/inputMedicaments.vue";

export default {
  components: { modal, inputMedicaments },
  props: {
    data: {
      type: Array,
      required: true
    },
    patient: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modeUpdate: false,
      modal: { is: false },
      formCreate: { doseUnit: null, numberOfTake: null }
    };
  },
  methods: {
    async onSave() {
      if (
        !this.formCreate.doseUnit ||
        !this.formCreate.dailyDosage ||
        !this.formCreate.medicament ||
        !this.formCreate.numberOfTake
      ) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Remplissez les champs manquants",
          timerAlert: 8000
        });
      }

      this.$vs.loading();

      await this.$store.dispatch("healthbook/SAVE_PATIENT_MEDICAMENT", {
        patientId: this.patient._id,
        data: {
          doseUnit: this.formCreate.doseUnit,
          doses: this.formCreate.dailyDosage,
          drugId: this.formCreate.medicamentID,
          frequence: null,
          moreInfo: this.formCreate.for,
          name: this.formCreate.medicament,
          prises: this.formCreate.numberOfTake
        },
        onData: ({ ok }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Traitement créé avec succès",
              timerAlert: 8000
            });

            EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

            this.$nextTick(() => {
              // Reset Form
              this.modal = {
                is: false
              };

              this.formCreate = {
                doseUnit: null,
                numberOfTake: null
              };
            });

            return;
          }

          this.$vs.loading.close();
        }
      });
    },
    async onDelete(med) {
      this.$vs.loading();

      await this.$store.dispatch("healthbook/REMOVE_PATIENT_MEDICAMENT", {
        patientId: this.patient._id,
        medicamentId: med._id,
        onData: ({ ok }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Traitement supprimé avec succès",
              timerAlert: 8000
            });

            EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

            return;
          }

          this.$vs.loading.close();
        }
      });
    },
    setMedicament(item) {
      if (!item.name) return;

      this.formCreate.medicament = item.name;
      this.formCreate.medicamentID = item.objectID;

      EventBus.$emit("closeMedicamentInput", { name: item.name });
    },
    onCloseModal() {
      this.modal.is = false;
    },
    numberPrise() {
      let jours = new Array(12);
      let jours_modifi = [];
      for (var i = 0; i < jours.length; i++) {
        jours_modifi.push({
          number: i + 1,
          label: `${i + 1} x/Jour`
        });
      }

      return jours_modifi;
    }
  }
};
</script>

<style></style>
