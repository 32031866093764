<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed"
  >
    <div class="flex flex-row justify-between gap-x-1">
      <div class="flex flex-row gap-x-1 items-center">
        <img src="/svg/healthbook/virus-line.svg" />
        <h1 class="text-xl font-EffraR text-black">Antécedents</h1>
      </div>

      <dok-button
        v-if="!modeUpdate"
        bg="white"
        custom-class="flex items-center gap-x-2 font-EffraM"
        @click.native="modeUpdate = true"
      >
        <img src="/svg/pen.svg" /> Modifier
      </dok-button>
      <dok-button
        v-if="modeUpdate"
        custom-class="flex items-center gap-x-2 font-EffraM"
        size="md"
        @click.native="modeUpdate = false"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          fill="none"
          viewBox="0 0 19 18"
        >
          <path
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15.5 4.5l-8.25 8.25L3.5 9"
          />
        </svg>
        Sauvegarder
      </dok-button>
    </div>

    <hr class="border border-solid border-gray-200" />
    <div class="grid gap-x-3 gap-y-4 grid-cols-1 lg:grid-cols-3 items-baseline">
      <!--------------Familiaux------------->
      <div
        class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
        style="border: solid 1px #ededed"
      >
        <h3 class="font-EffraR text-base" style="color: #5b5b5b">Familiaux</h3>
        <div
          v-if="!data.familiaux.length"
          class="
            flex flex-row
            gap-x-1
            text-base
            font-EffraM
            h-full
            justify-center
            items-center
          "
          style="color: #8a8a8a"
        >
          <img src="/svg/consultation/info/x.svg" /> Pas de données
        </div>
        <ul
          v-if="data.familiaux.length"
          class="grid gap-x-3 gap-y-4 grid-cols-1"
        >
          <li
            v-for="(fa, index) in data.familiaux"
            :key="index"
            class="relative"
          >
            <dok-button
              v-if="modeUpdate"
              bg="no-class"
              size="delete"
              custom-class="absolute w-6 h-6 flex justify-center items-center"
              style="top: 0px; right: -5px"
              @click.native="onDeleteFamiliaux(fa)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                />
              </svg>
            </dok-button>
            <span class="font-EffraR text-base text-grayDok-sidebar">
              {{ fa.name }}
            </span>
            <p class="font-EffraR text-black text-lg">{{ fa.moreInfo }}</p>
          </li>
        </ul>
        <!----ACTIONS---->
        <dok-button
          v-if="modeUpdate"
          size="md"
          bg="white"
          custom-class="flex item-center justify-start gap-x-2 font-EffraM"
          @click.native="openModal('familiaux')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#297AFB"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 3.75v10.5M3.75 9h10.5"
            />
          </svg>
          Ajouter
        </dok-button>
      </div>
      <!--------------Chirurgicaux------------->
      <div
        class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
        style="border: solid 1px #ededed"
      >
        <h3 class="font-EffraR text-base" style="color: #5b5b5b">
          Chirurgicaux
        </h3>
        <div
          v-if="!data.chirurgicaux.length"
          class="
            flex flex-row
            gap-x-1
            text-base
            font-EffraM
            h-full
            justify-center
            items-center
          "
          style="color: #8a8a8a"
        >
          <img src="/svg/consultation/info/x.svg" /> Pas de données
        </div>
        <ul class="flex flex-col gap-y-2" v-if="data.chirurgicaux.length">
          <li
            v-for="(chr, index) in data.chirurgicaux"
            :key="index"
            class="relative"
          >
            <dok-button
              v-if="modeUpdate"
              bg="no-class"
              size="delete"
              custom-class="absolute w-6 h-6 flex justify-center items-center"
              style="top: 0px; right: -5px"
              @click.native="onDeleteChirugicaux(chr)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                />
              </svg>
            </dok-button>
            <span class="font-EffraR text-base text-grayDok-sidebar">
              {{ moment()(chr.operationDate).format("DD/MM/YYYY") || "--" }}
            </span>
            <p class="font-EffraR text-black text-lg">{{ chr.operation }}</p>
          </li>
        </ul>
        <!----ACTIONS---->
        <dok-button
          v-if="modeUpdate"
          size="md"
          bg="white"
          custom-class="flex item-center justify-start gap-x-2 font-EffraM"
          @click.native="openModal('chirurgicaux')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#297AFB"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 3.75v10.5M3.75 9h10.5"
            />
          </svg>
          Ajouter
        </dok-button>
      </div>
      <!--------------Médicaux------------->
      <div
        class="rounded-lg p-5 flex flex-col gap-y-3 items-stretch h-full"
        style="border: solid 1px #ededed"
      >
        <h3 class="font-EffraR text-base" style="color: #5b5b5b">Médicaux</h3>
        <div
          v-if="!data.medicaux.length"
          class="
            flex flex-row
            gap-x-1
            text-base
            font-EffraM
            h-full
            justify-center
            items-center
          "
          style="color: #8a8a8a"
        >
          <img src="/svg/consultation/info/x.svg" /> Pas de données
        </div>
        <ul class="flex flex-col gap-y-2" v-if="data.medicaux.length">
          <li
            class="bg-dokBlue-lighter p-2 rounded-cu relative"
            v-for="(med, index) in data.medicaux"
            :key="index"
          >
            <dok-button
              v-if="modeUpdate"
              bg="no-class"
              size="delete"
              custom-class="absolute w-6 h-6 flex justify-center items-center"
              style="top: -2px; right: -5px"
              @click.native="onDeleteMedicaux(med)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10.5 3.5l-7 7M3.5 3.5l7 7"
                />
              </svg>
            </dok-button>
            <span class="font-EffraM text-black text-base">
              {{ med.moreInfo || "--" }}
            </span>
            <p class="font-EffraR text-black text-base">{{ med.name }}</p>
            <div class="flex justify-end font-EffraR text-sm">
              {{
                moment()(med.startTreatmentDate).format("DD/MM/YYYY") || "--"
              }}
            </div>
          </li>
        </ul>
        <!----ACTIONS---->
        <dok-button
          v-if="modeUpdate"
          size="md"
          bg="white"
          custom-class="flex item-center justify-start gap-x-2 font-EffraM"
          @click.native="openModal('medicaux')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
          >
            <path
              stroke="#297AFB"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 3.75v10.5M3.75 9h10.5"
            />
          </svg>
          Ajouter
        </dok-button>
      </div>
    </div>

    <!------------------------------------------ Modal Create ---------------------------------->
    <modal
      :isActive="modal.is"
      :exitModal="
        () => {
          modal.is = false;
        }
      "
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <!--------------------------------medicaux----------------------------------->
      <div class="p-5 flex flex-col gap-y-8" v-if="modal.type === 'medicaux'">
        <div class="flex justify-between gap-x-3">
          <h1 class="flex-1 font-EffraR text-2xl">Antécédents médicaux</h1>
          <button
            @click="
              () => {
                modal.is = false;
              }
            "
            class="bg-transparent border-none focus:outline-none cursor-pointer"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div class="flex flex-col gap-y-6">
          <div class="relative">
            <dok-input
              size="lg"
              d-placeholder="Recherhez ..."
              d-type="text"
              label="Sélectionnez une maladie"
              :d-model.sync="formCreate.pathologie"
              :d-focus="
                () => {
                  antecedentMedicauxDrop = true;
                }
              "
            >
              <template v-slot:leftIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 20.9999L16.65 16.6499"
                    stroke="#111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
              <template v-slot:rightIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#111111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </dok-input>
            <div
              v-if="antecedentMedicauxDrop"
              class="
                bg-greyx
                rounded-b
                max-h-200
                overflow-y-scroll
                flex flex-col
                gap-y-1
                absolute
                w-full
                scroll-items
                z-1
              "
            >
              <div class="flex justify-end pt-1">
                <dok-button
                  bg="none"
                  @click.native="
                    () => {
                      antecedentMedicauxDrop = false;
                    }
                  "
                >
                  <img src="/svg/x.svg" />
                </dok-button>
              </div>
              <template v-if="antecedentMedicaux.length">
                <div
                  v-for="(item, index) in antecedentMedicaux"
                  :key="index"
                  @click="setItemPathologie(item)"
                  class="
                    px-2
                    py-2
                    font-EffraR
                    cursor-pointer
                    hover:bg-grey-light
                  "
                >
                  {{ item.name }}
                </div>
              </template>
            </div>
          </div>
          <dok-input-date
            label="Depuis quand vous en souffrez ?"
            :d-model.sync="formCreate.startDate"
            size="lg"
          ></dok-input-date>
          <div class="flex flex-col gap-y-1">
            <span class="font-EffraR text-base text-gray-800"
              >Prenez vous un traitement ?</span
            >
            <div class="grid grid-cols-2 gap-x-3">
              <div
                class="
                  flex flex-row
                  gap-x-3
                  items-center
                  px-2
                  py-3
                  font-EffraR
                  text-black text-base
                "
                style="border: solid 1px #eee; border-radius: 4px"
                @click="formCreate.booleanTraitment = true"
              >
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    hover:bg-dokBlue-lighter
                    relative
                    flex
                    items-center
                    justify-center
                  "
                  :class="formCreate.booleanTraitment && 'bg-dokBlue-lighter'"
                  style="border: solid 1px #eaeaea"
                >
                  <i
                    class="fas fa-check text-dokBlue-ultra text-xl"
                    v-if="formCreate.booleanTraitment"
                  ></i>
                </div>
                Oui
              </div>
              <div
                class="
                  flex flex-row
                  gap-x-3
                  items-center
                  px-2
                  py-3
                  font-EffraR
                  text-black text-base
                "
                style="border: solid 1px #eee; border-radius: 4px"
                @click="formCreate.booleanTraitment = false"
              >
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    hover:bg-dokBlue-lighter
                    relative
                    flex
                    items-center
                    justify-center
                  "
                  :class="!formCreate.booleanTraitment && 'bg-dokBlue-lighter'"
                  style="border: solid 1px #eaeaea"
                >
                  <i
                    class="fas fa-check text-dokBlue-ultra text-xl"
                    v-if="!formCreate.booleanTraitment"
                  ></i>
                </div>
                Non
              </div>
            </div>
          </div>
          <input-medicaments
            d-label="Sélectionnez le/les médicaments"
            d-placeholder="Recherchez..."
            :set-item-parent="setMedicament"
            v-model="formCreate.medicament"
          >
          </input-medicaments>
          <dok-button
            size="lg"
            custom-class="flex items-center gap-x-2 items-center justify-center font-EffraM"
            @click.native="onSaveMedicaux"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 5V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 12H19.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Ajouter
          </dok-button>
        </div>
      </div>
      <!--------------------------------chirurgicaux----------------------------------->
      <div
        class="p-5 flex flex-col gap-y-8"
        v-if="modal.type === 'chirurgicaux'"
      >
        <div class="flex justify-between gap-x-3">
          <h1 class="flex-1 font-EffraR text-2xl">Antécédents Chirurgicaux</h1>
          <button
            @click="
              () => {
                modal.is = false;
              }
            "
            class="bg-transparent border-none focus:outline-none cursor-pointer"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-col gap-y-6">
          <dok-input
            size="lg"
            d-placeholder="Recherchez ..."
            d-type="text"
            label="Nom de l'opération"
            :d-model.sync="formCreate.operation"
          >
            <template v-slot:leftIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 20.9999L16.65 16.6499"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </dok-input>
          <dok-input-date
            label="Date de l'opération"
            :d-model.sync="formCreate.startDate"
            size="lg"
          ></dok-input-date>
          <dok-button
            size="lg"
            custom-class="flex items-center gap-x-2 items-center justify-center font-EffraM"
            @click.native="onSaveChirurgicaux"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 5V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 12H19.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Ajouter
          </dok-button>
        </div>
      </div>
      <!--------------------------------familiaux----------------------------------->
      <div class="p-5 flex flex-col gap-y-8" v-if="modal.type === 'familiaux'">
        <div class="flex justify-between gap-x-3">
          <h1 class="flex-1 font-EffraR text-2xl">Antécédents familiaux</h1>
          <button
            @click="
              () => {
                modal.is = false;
              }
            "
            class="bg-transparent border-none focus:outline-none cursor-pointer"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#111111"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-col gap-y-6">
          <div class="relative">
            <dok-input
              size="lg"
              d-placeholder="Recherchez ..."
              d-type="text"
              label="Sélectionnez une maladie"
              :d-model.sync="formCreate.pathologieFam"
              :d-focus="
                () => {
                  antecedentFamiliauxDrop = true;
                }
              "
            >
              <template v-slot:leftIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21 20.9999L16.65 16.6499"
                    stroke="#111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
              <template v-slot:rightIcon>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#111111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </dok-input>
            <div
              v-if="antecedentFamiliauxDrop"
              class="border w-full rounded text-sm bg-bgGrey mt-2"
            >
              <div class="flex justify-end pt-1">
                <dok-button
                  bg="none"
                  @click.native="
                    () => {
                      antecedentFamiliauxDrop = false;
                    }
                  "
                >
                  <img src="/svg/x.svg" />
                </dok-button>
              </div>
              <template v-if="!antecedentFamiliaux.length">
                <div
                  class="
                    flex flex-row
                    gap-x-1
                    text-base
                    font-EffraM
                    h-full
                    justify-center
                    items-center
                    pb-2
                  "
                  style="color: #8a8a8a"
                >
                  <img src="/svg/consultation/info/x.svg" /> Pas de données
                </div>
              </template>
              <template v-if="antecedentFamiliaux.length">
                <div class="max-h-300 overflow-y-scroll scroll">
                  <div
                    v-for="(item, index) in antecedentFamiliaux"
                    :key="index"
                    @click="setItemPathologieFamiliaux(item)"
                    class="
                      px-2
                      py-2
                      font-EffraR
                      text-base
                      cursor-pointer
                      hover:bg-grey-light
                    "
                  >
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <dok-select
            label="Relation de parenté"
            d-placeholder="Relation de parenté"
            size="lg"
            :d-model.sync="formCreate.relation"
          >
            <template v-slot:leftIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21 20.9999L16.65 16.6499"
                  stroke="#111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
            <template v-slot:rightIcon>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="#111111"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
            <template v-slot:options>
              <option
                v-for="(item, index) in listParent"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </template>
          </dok-select>
          <dok-button
            size="lg"
            custom-class="flex items-center gap-x-2 items-center justify-center font-EffraM"
            @click.native="onSaveFamiliaux"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 5V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.5 12H19.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Ajouter
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import _ from "lodash";
  import { EventBus } from "@/event-bus";
  import inputMedicaments from "@/views/dashboard/consultations/components/prescriptions/layouts/inputMedicaments.vue";
  import modal from "@/views/global-components/modal";
  import moment from "moment";

  export default {
    components: { modal, inputMedicaments },
    data() {
      return {
        modeUpdate: false,
        modal: {
          type: null,
          is: false,
        },
        listParent: [
          "Père",
          "Mère",
          "frère",
          "soeur",
          "Grand-père paternel",
          "Grand-mère paternel",
          "Grand-père maternel",
          "Grand-mère maternel",
          "Autre membre de la famille",
        ],
        /// Médicaux
        formCreate: {
          pathologie: null,
          booleanTraitment: false,
          medicament: null,
          startDate: moment().format("YYYY-MM-DD"),
        },
        antecedentFamiliaux: [],
        antecedentFamiliauxTemp: [],
        antecedentFamiliauxDrop: false,
        antecedentMedicaux: [],
        antecedentMedicauxTemp: [],
        antecedentMedicauxDrop: false,
      };
    },
    watch: {
      // Médicaux
      "formCreate.pathologie"(newVal, oldVal) {
        if (newVal.length < 1) {
          this.antecedentMedicaux = this.antecedentMedicauxTemp;
        }

        if (newVal.length > 2) {
          this.antecedentMedicauxDrop = true;

          let filterList = this.antecedentMedicaux.filter((item) => {
            return item.name.toLowerCase().indexOf(newVal.toLowerCase()) >= 0;
          });

          this.antecedentMedicaux = filterList;
        }
      },
      "formCreate.pathologieFam"(newVal, oldVal) {
        if (newVal.length < 1) {
          this.antecedentFamiliaux = this.antecedentFamiliauxTemp;
        }

        if (newVal.length > 2) {
          this.antecedentFamiliauxDrop = true;

          let filterList = this.antecedentFamiliaux.filter((item) => {
            return item.name.toLowerCase().indexOf(newVal.toLowerCase()) >= 0;
          });

          this.antecedentFamiliaux = filterList;
        }
      },
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
    },
    created() {
      // Médicaux
      this.listAntecedentMedicaux();
      this.listPathologieFamiliaux();
    },
    methods: {
      moment() {
        moment.locale("fr");
        return moment;
      },
      openModal(type) {
        this.modal.type = type;
        this.$nextTick(() => {
          this.modal.is = true;
        });
      },

      // Methods Familiaux
      async listPathologieFamiliaux() {
        await this.$store.dispatch("healthbook/GET_ANTECEDENT_FAMILIAUX_LIST", {
          patientId: this.patient._id,
          onData: ({ ok, data }) => {
            if (ok) {
              this.antecedentFamiliaux = data.datas;
              this.antecedentFamiliauxTemp = _.cloneDeep(data.datas);
            }
          },
        });
      },
      async onSaveFamiliaux() {
        if (!this.formCreate.relation || !this.formCreate.pathologieFam) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Remplissez les champs manquant",
            timerAlert: 8000,
          });
        }

        this.$vs.loading();

        await this.$store.dispatch(
          "healthbook/SAVE_PATIENT_ANTECEDENT_FAMILIAL",
          {
            patientId: this.patient._id,
            data: {
              antecedent: this.formCreate.refId && null,
              name: this.formCreate.relation,
              moreInfo: this.formCreate.pathologieFam,
            },
            onData: ({ ok }) => {
              if (ok) {
                EventBus.$emit("flashDok", {
                  type: "success",
                  message: "Antécédent familial créé avec succès",
                  timerAlert: 8000,
                });

                EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

                this.$nextTick(() => {
                  // Reset Form
                  this.modal = {
                    type: null,
                    is: false,
                  };

                  this.formCreate = {
                    pathologieFam: "",
                    pathologie: "",
                    booleanTraitment: false,
                    medicament: null,
                    startDate: null,
                  };
                });

                return;
              }

              this.$vs.loading.close();
            },
          }
        );
      },
      async onDeleteFamiliaux(item) {
        this.$vs.loading();

        await this.$store.dispatch(
          "healthbook/REMOVE_PATIENT_ANTECEDENT_FAMILIAUX",
          {
            patientId: this.patient._id,
            data: item,
            onData: ({ ok }) => {
              if (ok) {
                EventBus.$emit("flashDok", {
                  type: "success",
                  message: "Antécédent familial supprimé avec succès !",
                  timerAlert: 8000,
                });

                EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

                return;
              }

              this.$vs.loading.close();
            },
          }
        );
      },
      setItemPathologieFamiliaux(item) {
        this.formCreate.pathologieFam = item.name;
        this.formCreate.refId = item._id;
        this.$nextTick(() => {
          this.antecedentFamiliauxDrop = false;
        });
      },

      //  Methods Chirurgicaux ------------------------------------- DONE
      async onSaveChirurgicaux() {
        if (!this.formCreate.operation || !this.formCreate.startDate) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Remplissez les champs manquant",
            timerAlert: 8000,
          });
        }

        this.$vs.loading();

        await this.$store.dispatch(
          "healthbook/SAVE_PATIENT_ANTECEDENT_CHIRURGICAL",
          {
            patientId: this.patient._id,
            data: {
              operation: this.formCreate.operation,
              operationDate: this.formCreate.startDate,
            },
            onData: ({ ok, data }) => {
              if (ok) {
                EventBus.$emit("flashDok", {
                  type: "success",
                  message: "Antécédent chirurgical créé avec succès",
                  timerAlert: 8000,
                });

                EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

                this.$nextTick(() => {
                  // Reset Form
                  this.modal = {
                    type: null,
                    is: false,
                  };

                  this.formCreate = {
                    pathologie: "",
                    booleanTraitment: false,
                    medicament: null,
                    startDate: null,
                  };
                });

                return;
              }

              this.$vs.loading.close();
            },
          }
        );
      },
      async onDeleteChirugicaux(item) {
        this.$vs.loading();

        await this.$store.dispatch(
          "healthbook/REMOVE_PATIENT_ANTECEDENT_CHIRURGICAL",
          {
            patientId: this.patient._id,
            data: item,
            onData: ({ ok }) => {
              if (ok) {
                EventBus.$emit("flashDok", {
                  type: "success",
                  message: "Antécédent chirurgical supprimé avec succès !",
                  timerAlert: 8000,
                });

                EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

                return;
              }

              this.$vs.loading.close();
            },
          }
        );
      },

      //  Methods Médicaux ---------------------------------------------- DONE
      setItemPathologie(item) {
        this.formCreate.pathologie = item.name;
        this.formCreate.refId = item._id;
        this.$nextTick(() => {
          this.antecedentMedicauxDrop = false;
        });
      },
      setMedicament(data) {
        this.formCreate.medicament = data.name;
        EventBus.$emit("closeMedicamentInput", { name: data.name });
      },
      async onSaveMedicaux() {
        if (
          !this.formCreate.pathologie ||
          !this.formCreate.medicament ||
          !this.formCreate.startDate
        ) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Remplissez les champs manquant",
            timerAlert: 8000,
          });
        }

        this.$vs.loading();

        await this.$store.dispatch("healthbook/SAVE_PATIENT_ANTECEDENT_MEDICAL", {
          patientId: this.patient._id,
          data: {
            name: this.formCreate.pathologie,
            refId: this.formCreate.refId,
            moreInfo: this.formCreate.medicament,
            startTreatmentDate: this.formCreate.startDate,
            followTreatment: this.formCreate.booleanTraitment,
          },
          onData: ({ data }) => {
            if (data.ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: "Antécédent médical créé avec succès",
                timerAlert: 8000,
              });

              EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

              this.$nextTick(() => {
                // Reset Form
                this.modal = {
                  type: null,
                  is: false,
                };

                this.formCreate = {
                  pathologie: "",
                  booleanTraitment: false,
                  medicament: null,
                  startDate: null,
                };
              });

              return;
            }

            this.$vs.loading.close();
          },
        });
      },
      async listAntecedentMedicaux() {
        const { ok, data } = await this.$store.dispatch(
          "healthbook/SEARCH_ANTECEDENT_FAMILIAUX_LIST",
          {
            patientId: this.patient._id,
            limit: 100,
          }
        );

        if (ok) {
          this.antecedentMedicaux = data;
          this.antecedentMedicauxTemp = _.cloneDeep(data);
        }
      },
      async onDeleteMedicaux(item) {
        this.$vs.loading();
        this.$store.dispatch("healthbook/REMOVE_PATIENT_ANTECEDENT_MEDICAL", {
          patientId: this.patient._id,
          medId: item._id,
          onData: ({ data }) => {
            if (data.ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: "Antécédent médical supprimé avec succès !",
                timerAlert: 8000,
              });

              EventBus.$emit("RELOAD_RESUME_HELTHBOOK");

              return;
            }

            this.$vs.loading.close();
          },
        });
      },
    },
  };
</script>
