<template>
  <section>
    <div class="flex flex-col lg:flex-row gap-x-3 gap-y-4">
      <!---------------------Groupe Sanguin----------------->
      <div class="bg-white rounded-cu p-5 group relative">
        <div class="flex flex-row gap-x-4">
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            style="background: #f0f0f0"
          >
            <img src="/svg/healthbook/droplet.svg" />
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >Groupe Sanguin</span
            >
            <span class="font-EffraM text-black text-base">
              {{ bloodType || "--" }}
            </span>
          </div>
        </div>
        <div
          class="
            bg-white bg-opacity-95
            absolute
            inset-0
            hidden
            group-hover:flex
            items-center
            justify-center
          "
        >
          <dok-button
            bg="none"
            custom-class="flex items-center gap-x-2 text-dokBlue-ultra font-EffraM"
            @click.native="onEdit('groupSanguin')"
          >
            <img src="/svg/consultation/edit.svg" /> Modifier
          </dok-button>
        </div>
      </div>
      <!--------------------Poids & Taille & IMC----------------->
      <div class="bg-white rounded-cu p-5 group relative">
        <div class="flex flex-row items-center gap-x-4">
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            style="background: #f0f0f0"
          >
            <img src="/svg/healthbook/bmi.svg" />
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >Poids</span
            >
            <span class="font-EffraM text-black text-base">{{
              bodyInfo.poid || "--"
            }}</span>
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >Taille</span
            >
            <span class="font-EffraM text-black text-base">{{
              bodyInfo.taille || "--"
            }}</span>
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >IMC</span
            >
            <span class="font-EffraM text-black text-base">{{
              bodyInfo.imc || "--"
            }}</span>
          </div>
          <!-- <span
            class="bg-yellow-300 text-yellow-800 h-8 px-2 flex items-center justify-center font-EffraR rounded-cu"
            >Surpoids</span
          > -->
        </div>
        <div
          class="
            bg-white bg-opacity-95
            absolute
            inset-0
            hidden
            group-hover:flex
            items-center
            justify-center
          "
        >
          <dok-button
            bg="none"
            custom-class="flex items-center gap-x-2 text-dokBlue-ultra font-EffraM"
            @click.native="onEdit('physicalProperties')"
          >
            <img src="/svg/consultation/edit.svg" /> Modifier
          </dok-button>
        </div>
      </div>
      <!--------------------Maternity----------------->
      <div
        class="bg-white rounded-cu p-5 group relative"
        v-if="$auth.getUserInfo().gender === 'FEMALE'"
      >
        <div class="flex flex-row gap-x-4">
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            style="background: #f0f0f0"
          >
            <img src="/svg/healthbook/pregnancy.svg" />
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >Maternité</span
            >
            <span class="font-EffraM text-black text-base">
              {{ enceinte.isPregnant ? "Oui" : "Non" }}
            </span>
          </div>
        </div>
        <div
          class="
            bg-white bg-opacity-95
            absolute
            inset-0
            hidden
            group-hover:flex
            items-center
            justify-center
          "
        >
          <dok-button
            bg="none"
            custom-class="flex items-center gap-x-2 text-dokBlue-ultra font-EffraM"
            @click.native="onEdit('Maternity')"
          >
            <img src="/svg/consultation/edit.svg" /> Modifier
          </dok-button>
        </div>
      </div>
      <!--------------------Fumeur----------------->
      <div class="bg-white rounded-cu p-5 group relative">
        <div class="flex flex-row gap-x-4">
          <div
            class="w-12 h-12 rounded-full flex items-center justify-center"
            style="background: #f0f0f0"
          >
            <img src="/svg/healthbook/smoking.svg" />
          </div>
          <div class="flex flex-col gap-y-1">
            <span class="text-base font-EffraR" style="color: #5b5b5b"
              >Fumeur</span
            >
            <span class="font-EffraM text-black text-base">
              {{ smoking.isSmoker ? "Oui" : "Non" }}
            </span>
          </div>
        </div>
        <div
          class="
            bg-white bg-opacity-95
            absolute
            inset-0
            hidden
            group-hover:flex
            items-center
            justify-center
          "
        >
          <dok-button
            bg="none"
            custom-class="flex items-center gap-x-2 text-dokBlue-ultra font-EffraM"
            @click.native="onEdit('Fumeur')"
          >
            <img src="/svg/consultation/edit.svg" /> Modifier
          </dok-button>
        </div>
      </div>
    </div>
    <!-------------- Modal ---------------------->
    <modal
      :isActive="modal.is"
      :exitModal="onCloseModal"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <template v-if="modal.type === 'groupSanguin'">
        <div class="flex flex-col p-5">
          <div class="flex justify-between items-center">
            <h1 class="text-xl font-EffraR text-black flex-1">
              Groupe sanguin
            </h1>
            <dok-button
              bg="none"
              @click.native="
                () => {
                  modal.is = false;
                }
              "
            >
              <img src="/svg/x.svg" />
            </dok-button>
          </div>
        </div>
        <form-group-sanguin
          :bloodType="bloodType"
          :patient="patient"
        ></form-group-sanguin>
      </template>

      <template v-if="modal.type === 'physicalProperties'">
        <div class="flex flex-col p-5">
          <div class="flex justify-between items-center">
            <h1 class="text-xl font-EffraR text-black flex-1">
              Caractéristiques physiques
            </h1>
            <dok-button
              bg="none"
              @click.native="
                () => {
                  modal.is = false;
                }
              "
            >
              <img src="/svg/x.svg" />
            </dok-button>
          </div>
        </div>
        <physical-properties
          :bodyInfo="bodyInfo"
          :patient="patient"
        ></physical-properties>
      </template>

      <template v-if="modal.type === 'Fumeur'">
        <div class="flex flex-col p-5">
          <div class="flex justify-between items-start">
            <div class="flex flex-col gap-y-1">
              <h1 class="text-xl font-EffraR text-black flex-1">Mode de vie</h1>
            </div>
            <dok-button
              bg="none"
              @click.native="
                () => {
                  modal.is = false;
                }
              "
            >
              <img src="/svg/x.svg" />
            </dok-button>
          </div>
        </div>
        <fumeur :patient="patient" :smoking="smoking"></fumeur>
      </template>

      <template v-if="modal.type === 'Maternity'">
        <div class="flex flex-col p-5">
          <div class="flex justify-between items-center">
            <div class="flex flex-col gap-y-1">
              <h1 class="text-xl font-EffraR text-black flex-1">Enceinte</h1>
            </div>
            <dok-button
              bg="none"
              @click.native="
                () => {
                  modal.is = false;
                }
              "
            >
              <img src="/svg/x.svg" />
            </dok-button>
          </div>
        </div>
        <enceinte :patient="patient" :enceinte="enceinte"></enceinte>
      </template>
    </modal>
  </section>
</template>

<script>
import modal from "@/views/global-components/modal";
import formGroupSanguin from "../update/group_sanguin.vue";
import physicalProperties from "../update/physical_properties.vue";
import Fumeur from "../update/fumeur.vue";
import Enceinte from "../update/enceinte.vue";

export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
    bloodType: {
      type: String,
      required: false,
    },
    bodyInfo: {
      type: Object,
      required: false,
    },
    smoking: {
      type: Object,
      required: false,
    },
    enceinte: {
      type: Object,
      required: false,
    },
  },
  components: { modal, formGroupSanguin, physicalProperties, Fumeur, Enceinte },
  data() {
    return {
      modal: {
        is: false,
        type: null,
      },
    };
  },
  methods: {
    onCloseModal() {
      this.modal.type = null;
      this.modal.is = false;
    },
    onEdit(type) {
      this.modal.type = type;
      this.modal.is = true;
    },
  },
};
</script>
