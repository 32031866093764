<template>
  <div class="flex flex-col gap-y-8 p-5">
    <div class="grid grid-cols-2 gap-x-3 gap-y-3">
      <div
        style="border-radius: 4px; border: solid 1px #eaeaea;"
        class="p-5 font-EffraR flex items-center gap-x-2"
        v-for="(blod, i) in blood"
        :key="i"
        :value="blod"
      >
        <div
          class="h-8 w-8 rounded-full cursor-pointer hover:bg-dokBlue-lighter relative flex items-center justify-center"
          style="border: solid 1px #eaeaea;"
          @click="
            () => {
              currentBlood = blod;
            }
          "
        >
          <template v-if="currentBlood === blod">
            <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
          </template>
        </div>

        {{ blod }}
      </div>
    </div>

    <dok-button
      custom-class="flex items-center justify-center gap-x-2"
      size="lg"
      @click.native="onSaveGroupSanguin"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20.5 6l-11 11-5-5"
        />
      </svg>
      Sauvegarder
    </dok-button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  props: {
    patient: {
      type: Object,
      required: true
    },
    bloodType: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      blood: ["AB+", "AB-", "A+", "A-", "B+", "B-", "O+", "O-"],
      currentBlood: null
    };
  },
  created() {
    this.currentBlood = this.bloodType;
  },
  methods: {
    ...mapActions({
      updatePatientHealthProfile: "healthbook/UPDATE_PATIENT_HEALTH_PROFILE"
    }),
    async onSaveGroupSanguin() {
      this.$vs.loading();
      await this.updatePatientHealthProfile({
        patientId: this.patient._id,
        params: {
          bloodType: this.currentBlood
        },
        onData: ({ ok, data }) => {
          if (ok) {
            EventBus.$emit("flashDok", {
              type: "success",
              message: "Group sanguin modifié avec succès"
            });
            return EventBus.$emit("RELOAD_RESUME_HELTHBOOK");
          }
          this.$vs.loading.close();
        }
      });
    }
  }
};
</script>

<style></style>
